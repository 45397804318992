import { ApiClient } from '@travel/api-client';

import { ReservationDetailsItems } from 'ReservationDetails-Types';

export const fetchReservationDetailsItems = (
  http: ApiClient,
  reservationId: string,
): Promise<ReservationDetailsItems> =>
  http
    .get(`/consumer/mypage/reservation/details/${reservationId}`, {
      method: 'GET',
      headers: {
        'trv-currency': 'USD',
      },
      withAuthCode: true,
    })
    .catch((error: Error) => {
      console.error(error);
    })
    .then((data: ReservationDetailsItems) => {
      return data;
    });

export const resendToInputEmailAddress = (
  http: ApiClient,
  reservationId: string,
  email: string,
): Promise<any> =>
  http.post(
    `/notification-gateway-api/extranet/reservations/${reservationId}/notifications/email/latest`,
    {
      values: { emailAddress: email },
      ignoreCommonErrorHandler: true,
    },
  );
