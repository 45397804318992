import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ReservationDetailsErrors, ReservationDetailsItems } from 'ReservationDetails-Types';

import { fetchReservationDetailsAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchReservationDetailsAsync.request], (state, action) => true)
  .handleAction(
    [fetchReservationDetailsAsync.success, fetchReservationDetailsAsync.failure],
    (state, action) => false,
  );

export const items = createReducer({} as ReservationDetailsItems).handleAction(
  fetchReservationDetailsAsync.success,
  (state, action) => {
    return action.payload;
  },
);

export const errors = createReducer([] as ReservationDetailsErrors[]).handleAction(
  fetchReservationDetailsAsync.failure,
  (state, action) => action.payload,
);

export const receiptErrors = createReducer([] as ReservationDetailsErrors[]).handleAction(
  fetchReservationDetailsAsync.failure,
  (state, action) => action.payload,
);

const reservationDetailsReducer = combineReducers({
  isFetching,
  items,
  errors,
  receiptErrors,
});

export default reservationDetailsReducer;
export type ReservationDetailsState = ReturnType<typeof reservationDetailsReducer>;
