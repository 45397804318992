import { Request, Response } from 'express';
import { History } from 'history';
import { applyMiddleware, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { RootAction, RootState } from 'typesafe-actions';

import { ApiClient } from '@travel/api-client';

import rootReducer from './rootReducer';
import { composeEnhancers } from './utils';

const configureStore = (
  preloadedState = {},
  history?: History,
  req?: Request,
  res?: Response,
  apiClient?: ApiClient,
) => {
  const middlewares = [
    thunk.withExtraArgument({ history, req, res, apiClient }) as ThunkMiddleware<
      RootState,
      RootAction,
      { history: History<any>; req: Request; res: Response; apiClient: ApiClient }
    >,
  ];
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  return store;
};

export default configureStore;
