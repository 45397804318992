import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { Section } from 'components/ScrollingNavigation';

import { fetchAreaDetailsAsync } from 'store/areaDetails/actions';
import { updateIsBookmarked } from 'store/bookmarks/actions';
import {
  fetchProviderInformationAsync,
  setIsFindARoomBtnPresent,
  setSelectedNavSection,
} from 'store/providerInformation/actions';
import {
  fetchMoreProvidersAsync,
  fetchProviderListAsync,
  updateAreaName,
} from 'store/providerList/actions';

import { RecommendedProvider } from 'AreaDetails-Types';
import { ProviderInformation, ProviderInformationErrors } from 'ProviderInformation-Types';
import { ProviderItem } from 'ProviderList-Types';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchProviderInformationAsync.request], () => true)
  .handleAction(
    [fetchProviderInformationAsync.success, fetchProviderInformationAsync.failure],
    () => false,
  );

export const item = createReducer({} as ProviderInformation)
  .handleAction(fetchProviderInformationAsync.success, (_state, action) => action.payload)
  .handleAction(fetchProviderInformationAsync.failure, () => ({} as ProviderInformation));

export const errors = createReducer([] as ProviderInformationErrors[]).handleAction(
  fetchProviderInformationAsync.failure,
  (_state, action) => action.payload,
);

export const caches = createReducer({} as Record<string, Partial<ProviderInformation>>)
  // reset cache when area name has been changed
  .handleAction(updateAreaName, () => ({}))
  // caching basic data and reset state
  .handleAction(fetchAreaDetailsAsync.success, (_state, action) => {
    return action.payload.recommendedProviders.reduce(
      (result: Record<string, ProviderItem>, provider: RecommendedProvider) => {
        return { ...result, [provider.id]: { ...provider, name: provider.providerName } };
      },
      {},
    );
  })
  // caching basic data
  .handleAction(fetchProviderInformationAsync.success, (state, action) => ({
    ...state,
    [action.payload.id]: action.payload,
  }))
  .handleAction(fetchProviderListAsync.success, (state, action) => ({
    ...state,
    ...action.payload.response.providers.reduce(
      (result: Record<string, ProviderItem>, provider: ProviderItem) => {
        return { ...result, [provider.id]: provider };
      },
      {},
    ),
  }))
  .handleAction(fetchMoreProvidersAsync.success, (state, action) => ({
    ...state,
    ...action.payload.providers.reduce(
      (result: Record<string, ProviderItem>, provider: ProviderItem) => {
        return { ...result, [provider.id]: provider };
      },
      {},
    ),
  }))

  .handleAction(updateIsBookmarked, (state, action) => ({
    ...state,
    [action.payload.id]: { ...state[action.payload.id], isBookmarked: action.payload.isBookmarked },
  }));

export const isFindARoomBtnPresent = createReducer(false).handleAction(
  setIsFindARoomBtnPresent,
  (state, action) => action.payload,
);

export const selectedNavSection = createReducer({} as Section).handleAction(
  setSelectedNavSection,
  (state, action) => action.payload,
);

const providerInformationReducer = combineReducers({
  isFetching,
  item,
  caches,
  errors,
  isFindARoomBtnPresent,
  selectedNavSection,
});

export default providerInformationReducer;
export type ProviderInformationState = ReturnType<typeof providerInformationReducer>;
