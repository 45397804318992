import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { ReservationDetailsErrors, ReservationDetailsItems } from 'ReservationDetails-Types';

import { fetchReservationDetailsItems } from './api';

export const fetchReservationDetailsAsync = createAsyncAction(
  'FETCH_RESERVATION_DETAILS_REQUEST',
  'FETCH_RESERVATION_DETAILS_SUCCESS',
  'FETCH_RESERVATION_DETAILS_FAILURE',
)<undefined, ReservationDetailsItems, ReservationDetailsErrors[]>();

export const fetchReservationDetails = (reservationId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchReservationDetailsAsync.request());
  try {
    const response = await fetchReservationDetailsItems(apiClient, reservationId);
    dispatch(fetchReservationDetailsAsync.success(response));
  } catch (error) {
    dispatch(fetchReservationDetailsAsync.failure(error));
  }
};
