import { AppThunk, createAction, createAsyncAction, createStandardAction } from 'typesafe-actions';

import { SubscriptionErrors, SubscriptionItem } from 'Subscription-Types';

import { fetch, subscribe, unsubscribe } from './api';

export const fetchSubscriptionAsync = createAsyncAction(
  'FETCH_SUBSCRIPTION_REQUEST',
  'FETCH_SUBSCRIPTION_SUCCESS',
  'FETCH_SUBSCRIPTION_FAILURE',
)<undefined, SubscriptionItem, SubscriptionErrors[]>();

export const SUBSCRIPTION_SUCCESS = createStandardAction('SUBSCRIPTION_SUCCESS')();

export const doSubscribeAsync = createAsyncAction(
  'DO_SUBSCRIBE_REQUEST',
  'DO_SUBSCRIBE_SUCCESS',
  'DO_SUBSCRIBE_FAILURE',
)<undefined, string, SubscriptionErrors[]>();

export const doUnsubscribeAsync = createAsyncAction(
  'DO_UNSUBSCRIBE_REQUEST',
  'DO_UNSUBSCRIBE_SUCCESS',
  'DO_UNSUBSCRIBE_FAILURE',
)<undefined, string, SubscriptionErrors[]>();

export const clearSubscription = createAction('CLEAR_SUBSCRIPTION');

export const fetchSubscription = (email: string = ''): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const store = getState();

  const { isMember: isLogin } = store.member;

  dispatch(fetchSubscriptionAsync.request());

  if (!isLogin && !email) {
    dispatch(
      fetchSubscriptionAsync.success({
        hasSubscribedBefore: false,
        isSubscribed: false,
        isMember: false,
      } as SubscriptionItem),
    );
    return;
  }
  try {
    const subscription = await fetch(apiClient, email);
    dispatch(fetchSubscriptionAsync.success(subscription));
  } catch (e) {
    dispatch(fetchSubscriptionAsync.failure([{ status: e?.status, message: e?.message }]));
  }
};

// location: Value between 0-4: Account setting page : 0, Campaign page: 1, Top : 2, Area top : 3, Booking Step: 4
export const doSubscribe = (
  email: string = '',
  location: number,
  fetchNewStatus: boolean = false,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(doSubscribeAsync.request());
  try {
    const res = await subscribe(apiClient, email, location);
    dispatch(doSubscribeAsync.success(res));
    dispatch(SUBSCRIPTION_SUCCESS());
    if (fetchNewStatus) {
      dispatch(fetchSubscription(email));
    } //to do check impact on the old form
  } catch (e) {
    dispatch(doSubscribeAsync.failure([{ status: e?.status, message: e?.message }]));
  }
};

export const doUnsubscribe = (
  email: string = '',
  fetchNewStatus: boolean = false,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(doUnsubscribeAsync.request());
  try {
    const res = await unsubscribe(apiClient, email);
    dispatch(doUnsubscribeAsync.success(res));
    if (fetchNewStatus) {
      dispatch(fetchSubscription());
    }
  } catch (e) {
    dispatch(doUnsubscribeAsync.failure([{ status: e?.status, message: e?.message }]));
  }
};
