import { isNotEmptyArray, stringify } from '@travel/utils';

import { SortKeysType } from '../constants/provider';
import { featuresPathPrefix, pageNumberPathPrefix, sortkeyPathPrefix } from '../constants/seo';
import { Limitation } from '../types/markets';
import { isValidRoomSearchCondition } from './helper';

export const seoKeys = {
  startDate: 'startServiceDate',
  endDate: 'endServiceDate',
  noOfUnits: 'numberOfUnits',
  sortKey: 'sortKey',
  adults: 'numberOfAdults_{{room}}',
  childNo: 'numberOfChildren_{{room}}',
  childAge: 'ageOfChild_{{room}}-{{childNum}}',
  featureManagementName: 'featureManagementName_{{number}}',
  itemId: 'itemID',
  ratePlanId: 'ratePlanID',
  itemRatePlanId: 'itemRatePlanID',
  promotionCode: 'promotionCode',
  salesPromotionId: 'salesPromotionId',
  appliedSalesPromotionId: 'appliedSalesPromotionId_{{number}}',
};

export const deepLinkKeys = {
  filterComponent: 'filterComponent',
  lat: 'lat',
  lng: 'lng',
  zoomLevel: 'zoomLevel',
  hideSalesPromotion: 'hideSalesPromotion',
  searchText: 'searchText',
  ranSiteID: 'ranSiteID',
  ranDate: 'ranDate',
  scid: 'scid',
};

type SearchParams = {
  startDate?: string;
  endDate?: string;
  noOfUnits?: string | number;
  adults?: string | number;
  childrenAges?: string;
  promotionCode?: string;
  salesPromotionId?: string;
  freeCancellation?: string;

  // provider list
  sortKey?: string;
  features?: string;

  // booking steps
  itemId?: string;
  ratePlanId?: string;
  itemRatePlanId?: string;
  appliedSalesPromotionIds?: string | string[] | null;
  perRoomPriceBeforeDiscount?: string;
  perRoomPriceAfterDiscount?: string | null;
  isCouponSpecified?: string;
};

type DeepLinkParams = {
  searchText?: string;

  lat?: number;
  lng?: number;
  zoomLevel?: number;

  hideSalesPromotion?: boolean;
  filterComponent?: string;
  // RAN parameters
  ranSiteID?: string;
  ranDate?: number;
  scid?: string;
};

/**
 * To convert the app params to query string according to SEO specification
 */
export function toSEOSearchObj(
  searchObj: SearchParams & DeepLinkParams & { lId?: string },
  limitations?: Limitation,
) {
  const {
    noOfUnits,
    adults,
    childrenAges,
    startDate,
    endDate,
    sortKey,
    promotionCode,
    salesPromotionId,
    itemId,
    ratePlanId,
    itemRatePlanId,
    hideSalesPromotion,
    appliedSalesPromotionIds,
    ...restParams
  } = searchObj;

  const maximumNumberOfUnit = limitations?.searchLimitation?.maximumNumberOfUnit || 10;
  let modifiedNoOfUnits = isValidRoomSearchCondition(noOfUnits) ? noOfUnits : '';
  let modifiedAdults = {};
  let modifiedChildrenAges = {};

  if (modifiedNoOfUnits && adults) {
    if (Number(modifiedNoOfUnits) > maximumNumberOfUnit) {
      modifiedNoOfUnits = maximumNumberOfUnit;
    }
    const noOfUnitsNumber = parseInt(String(modifiedNoOfUnits));

    // adults
    if (isValidRoomSearchCondition(adults)) {
      modifiedAdults = Array(noOfUnitsNumber)
        .fill(0)
        .reduce((rs, _, index) => {
          const key = seoKeys['adults'].replace(/{{room}}/g, `${index + 1}`);
          return { ...rs, [key]: adults };
        }, {});
    }

    // childrenAges
    if (childrenAges) {
      const childrenAgesArr = decodeURIComponent(childrenAges)
        .split(',')
        .filter(isValidRoomSearchCondition);

      const childCount = childrenAgesArr.length;
      modifiedChildrenAges = Array(noOfUnitsNumber)
        .fill(0)
        .reduce((rs, _, index) => {
          const roomNo = `${index + 1}`;
          const childNokey = seoKeys['childNo'].replace(/{{room}}/g, roomNo);
          const roomChildrenAges = childrenAgesArr.reduce((roomRs, age, childNo) => {
            let key = seoKeys['childAge']
              .replace(/{{room}}/g, roomNo)
              .replace(/{{childNum}}/g, `${childNo + 1}`);
            return { ...roomRs, [key]: age };
          }, {});

          return { ...rs, [childNokey]: childCount, ...roomChildrenAges };
        }, {});
    }
  }

  // applied sales promotion
  let modifiedAppliedPromotionIds;
  if (appliedSalesPromotionIds) {
    const appliedSalesPromotionIdsArr =
      typeof appliedSalesPromotionIds === 'string'
        ? decodeURIComponent(appliedSalesPromotionIds)?.split(',')
        : [];
    modifiedAppliedPromotionIds = isNotEmptyArray(appliedSalesPromotionIdsArr)
      ? appliedSalesPromotionIdsArr.reduce((prev, currentSalesPromotionId, index) => {
          const key = seoKeys['appliedSalesPromotionId'].replace(/{{number}}/g, `${index + 1}`);
          return { ...prev, [key]: currentSalesPromotionId };
        }, {})
      : {};
  }

  return {
    ...restParams,

    // sales promotion
    ...(promotionCode && { [seoKeys['promotionCode']]: promotionCode }),
    ...(salesPromotionId && { [seoKeys['salesPromotionId']]: salesPromotionId }),
    // TODO confirm what to do with this param.
    ...(hideSalesPromotion === false && { hideSalesPromotion: 'false' }),
    // providers
    ...(sortKey && { [seoKeys['sortKey']]: sortKey }),
    // booking step
    ...(itemId && { [seoKeys['itemId']]: itemId }),
    ...(ratePlanId && { [seoKeys['ratePlanId']]: ratePlanId }),
    ...(itemRatePlanId && { [seoKeys['itemRatePlanId']]: itemRatePlanId }),
    // room
    ...(startDate && { [seoKeys['startDate']]: startDate }),
    ...(endDate && { [seoKeys['endDate']]: endDate }),
    ...(noOfUnits && { [seoKeys['noOfUnits']]: modifiedNoOfUnits }),
    ...modifiedAdults,
    ...modifiedChildrenAges,
    ...modifiedAppliedPromotionIds,
  };
}

/**
 * To convert the app params to query string according to SEO specification
 */
/**
 * To convert the app params to query string according to SEO specification
 */
export function toSEOSearchString<
  T extends SearchParams &
    DeepLinkParams & { lId?: string | undefined; category?: string; email?: string }
>(searchObj: T, limitations?: Limitation) {
  return stringify(toSEOSearchObj(searchObj, limitations));
}
/**
 * To convert the SEO params to app params
 * App params are the property which exist on the Search Components
 */
export function toAppSearchObject(
  searchObj?: {
    [key: string]: string | undefined;
  },
  limitations?: Limitation,
): Record<string, string | undefined | Array<string>> {
  if (!searchObj) return {};

  const maximumNumberOfUnit = limitations?.searchLimitation?.maximumNumberOfUnit || 10;
  let noOfUnits = parseInt((searchObj[seoKeys['noOfUnits']] as string) || '0');
  let adults: string | undefined = undefined;
  let childrenAges: Array<string | undefined> | undefined = undefined;
  if (noOfUnits > 0) {
    if (noOfUnits > maximumNumberOfUnit) {
      noOfUnits = maximumNumberOfUnit;
    }
    // The adults number and childrenAges will be the same value in every room
    // adults
    const adultPrefix = seoKeys['adults'].replace(/{{room}}/g, `1`);
    adults = Object.entries(searchObj).find(([key]) => key.includes(adultPrefix))?.[1]; // get value at index 1

    // childrenAges
    const childAgePrefix = seoKeys['childAge']
      .replace(/{{room}}/g, `1`)
      .replace(/{{childNum}}/g, ``);
    childrenAges = Object.entries(searchObj)
      .filter(([key]) => key.includes(childAgePrefix))
      .map(filtered => filtered[1]); // get value at index 1
  }

  const appliedSalesPromotionIdPrefix = seoKeys['appliedSalesPromotionId'].replace(
    /{{number}}/g,
    ``,
  );
  const appliedSalesPromotionIds = Object.entries(searchObj).reduce(
    (result: string[] | undefined, [key, value]) => {
      if (key.includes(appliedSalesPromotionIdPrefix) && value)
        return [...(result || []), value as string];
      return result;
    },
    undefined,
  );

  // objective features
  const featuresPrefix = seoKeys['featureManagementName'].replace(/{{number}}/g, ``);
  const features = Object.keys(searchObj)
    .filter(key => key.includes(featuresPrefix))
    .map(filtered => searchObj[filtered])
    .join();

  return {
    // sales promotion
    promotionCode: searchObj[seoKeys['promotionCode']],
    salesPromotionId: searchObj[seoKeys['salesPromotionId']],
    // room
    startDate: searchObj[seoKeys['startDate']],
    endDate: searchObj[seoKeys['endDate']],
    noOfUnits: (noOfUnits && noOfUnits.toString()) || undefined,
    adults,
    childrenAges: isNotEmptyArray(childrenAges) ? childrenAges.join() : undefined,
    // providers
    sortKey: searchObj[seoKeys['sortKey']],
    features,
    // booking step
    itemId: searchObj[seoKeys['itemId']],
    ratePlanId: searchObj[seoKeys['ratePlanId']],
    itemRatePlanId: searchObj[seoKeys['itemRatePlanId']],
    appliedSalesPromotionIds: isNotEmptyArray(appliedSalesPromotionIds)
      ? appliedSalesPromotionIds
      : undefined,
    ...searchObj,
  };
}

/**
 * To extract search params from requested Provider List
 * (Canonical URL only)
 */
export type SEOExtraParams = {
  page?: number;
  sortKey?: SortKeysType;
  features?: string;
};

export function extractProviderListURLQueries(
  // ex. /objective_feature1-feature2-feature3
  featuresString?: string,
  // ex. /sortkey_recommended
  sortkeyString?: string,
  // ex. /page_2
  pageString?: string,
): SEOExtraParams {
  if (!featuresString && !sortkeyString && !pageString) return {};

  const sortKey = sortkeyString?.replace(sortkeyPathPrefix, '') as SortKeysType;
  const features = featuresString
    ?.replace(featuresPathPrefix, '')
    .split('-')
    .join(',');

  return {
    page: pageString ? Number(pageString.replace(`${pageNumberPathPrefix}`, '')) : 1,
    sortKey,
    features,
  };
}
