import React, { useCallback, useRef, useState, ReactNode } from 'react';

import IconText from '../IconText';
import { iconPositionType } from '../IconTextLink';
import Popup from '../Popup';

import cx from '../../utils/classnames';

import styles from './iconTextList.module.scss';

export type IconTextItem = {
  /** Icon to display */
  icon?: ReactNode;
  /** Unique id to identify item */
  id?: string;
  /** Text to display */
  text?: string;
  /** Detail title to display */
  title?: string;
  /** Detail description to display */
  description?: string;
  /** Position of display icon */
  iconPosition?: iconPositionType;
  /** Custom style for text */
  textClassName?: string;
  /** Custom style for popup */
  popupClassName?: string;
};

type IconTextProps = {
  className?: string;
  /** Icon to display */
  spaceBetween?: number;
} & IconTextItem;

type Props = {
  className?: string;
  /** Custom style for iconText */
  iconTextClassName?: string;
  /** Spacing between icon and text inside IconText component */
  iconTextSpacing?: number;
  /** IconText Items to display */
  items: Array<IconTextItem>;
  /** Display direction type */
  type?: 'horizontal' | 'vertical';
  /** Spacing between IconText item */
  spaceBetween?: number;
};

export function IconTextWithPopup(props: IconTextProps) {
  const { className, title, description, popupClassName, ...restProps } = props;
  const anchorEl = useRef<HTMLLIElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  // to show detail-popup
  const onClick = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);

  return (
    <li
      className={cx(className, styles.itemWrapper, styles.clickable)}
      ref={anchorEl}
      data-testid="iconTextWithPopup"
    >
      <IconText onClick={onClick} {...restProps} />
      {isOpen && (
        <Popup
          className={cx(styles.popup, popupClassName)}
          isOpen={isOpen}
          onClose={onClose}
          anchorEl={anchorEl.current}
        >
          {title && <span className={styles.title}>{title}</span>}
          {description && <span>{description}</span>}
        </Popup>
      )}
    </li>
  );
}

export default function IconTextList(props: Props) {
  const {
    className,
    iconTextClassName,
    items,
    iconTextSpacing = 4,
    type = 'horizontal',
    spaceBetween = 16,
    ...rest
  } = props;

  const spaceDirection = type === 'vertical' ? 'marginBottom' : 'marginRight';
  const customStyleAttr = spaceBetween && { style: { [spaceDirection]: spaceBetween } };

  return (
    <ul
      className={cx(className, styles.wrapper, styles[type])}
      data-testid="iconTextList"
      {...rest}
    >
      {items.map((item, index) => {
        const hasPopup = Boolean(item.title || item.description);

        return hasPopup ? (
          <IconTextWithPopup
            key={item.id || item.text}
            className={iconTextClassName}
            spaceBetween={iconTextSpacing}
            data-testid={`iconTextWithPopup-${index}`}
            {...item}
            {...(index !== items.length - 1 && customStyleAttr)}
          />
        ) : (
          <li
            key={item.id || item.text}
            className={cx(iconTextClassName, styles.itemWrapper)}
            data-testid={`iconTextItem-${index}`}
          >
            <IconText
              spaceBetween={iconTextSpacing}
              data-testid={`iconText-${index}`}
              {...item}
              {...(index !== items.length - 1 && customStyleAttr)}
            />
          </li>
        );
      })}
    </ul>
  );
}
