import loadable from '@loadable/component';
import React from 'react';

import UniversalRouter from 'universal-router';

import { Wifi } from '@travel/icons/service';
import { IS_GUEST_BOOKING_USER, TOKEN_KEY } from '@travel/traveler-core/constants/cookies';
import { extractProviderListURLQueries, toAppSearchObject } from '@travel/traveler-core/utils/seo';
import { getCookie, isEmptyArray, isNotEmptyValue, parseQueryString } from '@travel/utils';

import accountSettingsPageResolver from 'pages/AccountSettingsPage/resolver';
import areaPageResolver from 'pages/AreaPage/resolver';
import authRequestPageResolver from 'pages/AuthRequestPage/resolver';
import bookingStep1PageResolver from 'pages/BookingStep1/resolver';
import bookingStep2PageResolver from 'pages/BookingStep2/resolver';
import bookmarkListPageResolver from 'pages/BookmarkListPage/resolver';
import cancelStep1Resolver from 'pages/CancelStep1/resolver';
import couponDetailPageResolver from 'pages/CouponDetailPage/resolver';
import couponListPageResolver from 'pages/CouponListPage/resolver';
import emailSubscriptionManagementPageResolver from 'pages/EmailSubscriptionManagementPage/resolver';
import guestReviewDetailPage from 'pages/GuestReviewDetailPage/resolver';
import guestReviewListPage from 'pages/GuestReviewListPage/resolver';
import guideUserPageResolver from 'pages/GuideUserPage/resolver';
import legacyReservationDetailsPageResolver from 'pages/LegacyReservationDetails/resolver';
import legacyReservationListPageResolver from 'pages/LegacyReservationListPage/resolver';
import loginOrGuestPageResolver from 'pages/LoginOrGuestPage/resolver';
import modificationStep1Resolver from 'pages/ModificationStep1/resolver';
import modificationStep2Resolver from 'pages/ModificationStep2/resolver';
import modificationStep3Resolver from 'pages/ModificationStep3/resolver';
import nonMemberPageResolver from 'pages/NonMember/resolver';
import notificationDetailsResolver from 'pages/NotificationDetails/resolver';
import notificationsPageResolver from 'pages/NotificationsPage/resolver';
import postReviewPageResolver from 'pages/PostReviewPage/resolver';
import privacyPolicyPageResolver from 'pages/PrivacyPolicyPage/resolver';
import providerGuestReviewPageResolver from 'pages/ProviderGuestReviewPage/resolver';
import providerInformationPageResolver from 'pages/ProviderInformationPage/resolver';
import providerListPageResolver, {
  SearchProps as ProviderListPageSearchProps,
} from 'pages/ProviderListPage/resolver';
import reservationDetailsPageResolver from 'pages/ReservationDetails/resolver';
import reservationListPageResolver from 'pages/ReservationListPage/resolver';
import SkeletonPage from 'pages/SkeletonPage';
import topPageResolver from 'pages/TopPage/resolver';

import { pushLocation, setPreviousPageName } from 'store/__router/actions';
import { guestLogout, setIsGuestBookingUser } from 'store/authCode/actions';
import { updateMapExpansionState } from 'store/providerList/actions';
import { fetchSuggestions } from 'store/suggestions/actions';
import { getItems } from 'store/suggestions/selectors';

import { NewQuery as BS1Query } from 'BookingStep-Types';
import { RAT_PAGE_TYPE_OBJ } from 'constants/ratAnalytics';
import App from 'core/App';
import appResolver, { appResolverMember, appResolverNotification } from 'core/App/resolver';
import paths, { baseUrl } from 'core/universalRouter/paths';
import { MarketItem } from 'Markets-Types';
import { validateDateRange } from 'utils/date';

import { getIsWithInCampaignPeriod } from '../../store/featureConfig/selectors';
import { universalRouterProps } from './types';

const loadOptions = {
  fallback: <SkeletonPage />,
};

type RecursiveObject = {
  [key: string]: RecursiveObject | any;
};

export const getLimitations = (store: RecursiveObject) => {
  const marketItems = store?.markets?.items;
  const currentMarket = store?._i18n?.market;

  const currentMarketItem = marketItems?.find(
    (market: MarketItem) => market.marketCode === currentMarket.marketCode.toUpperCase(),
  );
  return currentMarketItem?.limitations;
};

export default (props: universalRouterProps) => {
  const isServer = props.isServer || false;
  const store = props.store.getState();

  const limitations = getLimitations(store);

  const isWithInCampaignPeriod = getIsWithInCampaignPeriod(store);

  const routes = [
    {
      path: paths.auth.path,
      async action(context: any) {
        if (!context.query) {
          props.res?.redirect(paths.top.pathResolver());
          return undefined;
        }

        const LoginRedirection = loadable(
          () => import(/* webpackChunkName: "pages-LoginRedirection" */ 'pages/LoginRedirection'),
          loadOptions,
        );
        return <LoginRedirection {...context} />;
      },
    },
    {
      path: '/',
      async action(context: any) {
        if (isServer) {
          // On server: fetch member on server entry
          await appResolverMember(props);
        } else if (!context.skipResolver) {
          // On client: fetch member and notification on client entry
          appResolver(props);
        } else {
          // On client: fetch notification on client entry (this case happen on isServer only)
          appResolverNotification(props);
        }

        let children;
        try {
          children = await context.next();
        } catch (e) {
          console.error(e);
          children = null;
        }

        return (
          <App {...context} key="app">
            {children}
          </App>
        );
      },
      children: [
        {
          path: paths.top.path,
          pageName: RAT_PAGE_TYPE_OBJ.top.parentName,
          async action(context: any) {
            const TopPage = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-TopPage", webpackPrefetch: true  */ 'pages/TopPage'
                ),
              loadOptions,
            );

            const query = toAppSearchObject(context.query, limitations);

            if (isServer) {
              // to support open search
              if (context.query?.q) {
                try {
                  const store = props.store.getState();
                  await props.store.dispatch(fetchSuggestions(context.query?.q));
                  const suggestions = getItems(store);
                  const targetPlace = suggestions[0];

                  const pathResolver =
                    targetPlace.category === 'PROVIDER'
                      ? paths.providerInfo.pathResolver
                      : targetPlace.type === 'AREA' && targetPlace.category === 'SUBDIVISION'
                      ? paths.area.pathResolver
                      : paths.providerList.pathResolver;

                  props.res?.redirect(pathResolver(suggestions[0].pathId));
                  return undefined;
                } catch (error) {
                  // do nothing
                }
              }

              await topPageResolver(props);
            } else if (!context.skipResolver) {
              topPageResolver(props);
            }

            return <TopPage {...context} query={query} />;
          },
        },
        {
          path: paths.area.path,
          pageName: RAT_PAGE_TYPE_OBJ.area.parentName,
          async action(context: any) {
            const AreaPage = loadable(
              () => import(/* webpackChunkName: "pages-AreaPage" */ 'pages/AreaPage'),
              loadOptions,
            );

            const query = toAppSearchObject(context.query, limitations);

            const search = {
              params: { pathId: context.params.pathId },
              query,
            };

            if (isServer) {
              //TODO: Not yet decided about what should be server side rendered and what should not be
              await areaPageResolver(props, search);
            } else if (!context.skipResolver) {
              areaPageResolver(props, search);
            }

            return <AreaPage {...context} query={query} />;
          },
        },
        {
          path: paths.providerList.path,
          isProviderListPage: true,
          async action(context: any) {
            const ProviderListPage = loadable(
              () =>
                import(/* webpackChunkName: "pages-ProviderListPage" */ 'pages/ProviderListPage'),
              loadOptions,
            );

            const query = toAppSearchObject(context.query, limitations);
            const seoQuery = extractProviderListURLQueries(
              context.params?.features,
              context.params?.sortkey,
              context.params?.page,
            );

            const isValidDatedSearch = validateDateRange(
              query.startDate as string,
              query.endDate as string,
            );

            if (!isValidDatedSearch) {
              // change it to undated search if the date range is invalid
              query.startDate = undefined;
              query.endDate = undefined;
            }

            const search: ProviderListPageSearchProps = {
              params: context.params,
              query: { ...query, ...seoQuery },
            };

            props.store.dispatch(
              setPreviousPageName(
                query.startDate || query.endDate
                  ? RAT_PAGE_TYPE_OBJ.providerListDated.parentName
                  : RAT_PAGE_TYPE_OBJ.providerListUndated.parentName,
              ),
            );

            if (isServer) {
              //TODO: Not yet decided about what should be server side rendered and what should not be
              await providerListPageResolver(props, search);
            } else if (!context.skipResolver) {
              providerListPageResolver(props, search);
            }

            return <ProviderListPage {...context} query={search.query} />;
          },
        },
        {
          path: paths.providerInfo.path,
          async action(context: any) {
            const ProviderInformationPage = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ProviderInformationPage" */ 'pages/ProviderInformationPage'
                ),
              loadOptions,
            );

            const query = toAppSearchObject(context.query, limitations);

            const isValidDatedSearch = validateDateRange(
              query.startDate as string,
              query.endDate as string,
            );

            if (!isValidDatedSearch) {
              // change it to undated search if the date range is invalid
              query.startDate = undefined;
              query.endDate = undefined;
            }

            const search = {
              params: context.params,
              query,
            };

            props.store.dispatch(
              setPreviousPageName(
                query.startDate || query.endDate
                  ? RAT_PAGE_TYPE_OBJ.providerInfoDated.parentName
                  : RAT_PAGE_TYPE_OBJ.providerInfoUndated.parentName,
              ),
            );

            if (isServer) {
              //TODO: Not yet decided about what should be server side rendered and what should not be
              await providerInformationPageResolver(props, search);
            } else if (!context.skipResolver) {
              providerInformationPageResolver(props, search);
            }

            return <ProviderInformationPage {...context} query={query} shouldLazyLoad={true} />;
          },
        },
        {
          path: paths.providerReviewInfo.path,
          pageName: RAT_PAGE_TYPE_OBJ.reviewList.parentName,
          async action(context: any) {
            const ProviderGuestReviewPage = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ProviderGuestReviewPage" */ 'pages/ProviderGuestReviewPage'
                ),
              loadOptions,
            );
            const search = { params: context.params };

            if (isServer) {
              //TODO: Not yet decided about what should be server side rendered and what should not be
              await providerGuestReviewPageResolver(props, search);
            } else if (!context.skipResolver) {
              await providerGuestReviewPageResolver(props, search);
            }

            return <ProviderGuestReviewPage {...context} />;
          },
        },
        {
          path: paths.guestReviewComplete.path,
          isNotAllowedFirstLanding: true,
          isReservationManagementPage: true,
          async action(context: any) {
            const PostReviewStep2Page = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-PostReviewStep2Page" */ 'pages/PostReviewStep2Page'
                ),
              loadOptions,
            );
            return <PostReviewStep2Page {...context} />;
          },
        },
        {
          path: paths.guestReviewPost.path,
          pageName: RAT_PAGE_TYPE_OBJ.postReview.parentName,
          isReservationManagementPage: true,
          async action(context: any) {
            const PostReviewPage = loadable(
              () => import(/* webpackChunkName: "pages-PostReviewPage" */ 'pages/PostReviewPage'),
              loadOptions,
            );
            const search = { params: context.params };

            if (isServer) {
              //TODO: Not yet decided about what should be server side rendered and what should not be
              await postReviewPageResolver(props, search);
            } else if (!context.skipResolver) {
              await postReviewPageResolver(props, search);
            }

            return <PostReviewPage {...context} />;
          },
        },
        {
          path: paths.guestReviewList.path,
          isReservationManagementPage: true,
          pageName: RAT_PAGE_TYPE_OBJ.guestReviewList.parentName,
          async action(context: any) {
            const GuestReviewListPage = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-GuestReviewListPage" */ 'pages/GuestReviewListPage'
                ),
              loadOptions,
            );
            const search = { params: context.params, query: context.query };

            if (isServer) {
              //TODO: Not yet decided about what should be server side rendered and what should not be
              await guestReviewListPage(props, search);
            } else if (!context.skipResolver) {
              guestReviewListPage(props, search);
            }

            return <GuestReviewListPage {...context} />;
          },
        },
        {
          path: paths.guestReviewDetail.path,
          isReservationManagementPage: true,
          pageName: RAT_PAGE_TYPE_OBJ.guestReviewDetail.parentName,
          async action(context: any) {
            const GuestReviewDetailPage = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-GuestReviewDetailPage" */ 'pages/GuestReviewDetailPage'
                ),
              loadOptions,
            );
            const search = { params: context.params, query: context.query };

            if (isServer) {
              //TODO: Not yet decided about what should be server side rendered and what should not be
              await guestReviewDetailPage(props, search);
            } else if (!context.skipResolver) {
              guestReviewDetailPage(props, search);
            }

            return <GuestReviewDetailPage {...context} />;
          },
        },
        {
          path: paths.guestBooking.path,
          async action(context: { params: { toWhere: string; deviceId: string; token: string } }) {
            const { toWhere, deviceId } = context.params;
            try {
              const res = (await nonMemberPageResolver(props, context)) as {
                token?: string;
              };
              if (res && res.token) {
                const NonMember = loadable(
                  () => import(/* webpackChunkName: "pages-NonMember" */ 'pages/NonMember'),
                  loadOptions,
                );
                return (
                  <NonMember toWhere={toWhere} deviceId={deviceId} token={res.token} {...context} />
                );
              }
            } catch ({ commonErrorHandler, status }) {
              if (status === 401) {
                const AuthMailFailPage = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-AuthMailFailPage" */ 'pages/AuthMailFailPage'
                    ),
                  loadOptions,
                );
                const callback = Buffer.from(toWhere, 'base64').toString();
                return <AuthMailFailPage {...context} callback={callback} />;
              } else {
                commonErrorHandler();
              }
            }
          },
        },
        {
          path: baseUrl.booking,
          children: [
            {
              path: paths.reservationForm.subPath,
              async action(context: any) {
                const ReservationEntrancePage = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-ReservationEntrancePage" */ 'pages/ReservationEntrancePage'
                    ),
                  loadOptions,
                );

                return <ReservationEntrancePage {...context} query={context.query} />;
              },
            },
            {
              path: paths.legacyReservationForm.subPath,
              async action(context: any) {
                const LegacyReservationEntrancePage = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LegacyReservationEntrancePage" */ 'pages/LegacyReservationEntrancePage'
                    ),
                  loadOptions,
                );

                return <LegacyReservationEntrancePage {...context} query={context.query} />;
              },
            },
            {
              path: paths.bookingMailRequest.subPath,
              isGuestOnly: true,
              pageName: RAT_PAGE_TYPE_OBJ.authRequest.parentName,
              async action(context: any) {
                const AuthRequestPage = loadable(
                  () =>
                    import(/* webpackChunkName: "pages-AuthRequestPage" */ 'pages/AuthRequestPage'),
                  loadOptions,
                );

                const query = toAppSearchObject(context.query, limitations);

                if (isServer) {
                  await authRequestPageResolver(props, query);
                } else if (!context.skipResolver) {
                  authRequestPageResolver(props, query);
                }

                return <AuthRequestPage {...context} />;
              },
            },
            {
              path: paths.bookingMailAccept.subPath,
              isNotAllowedFirstLanding: true,
              async action(context: any) {
                const AuthSentPage = loadable(
                  () => import(/* webpackChunkName: "pages-AuthSentPage" */ 'pages/AuthSentPage'),
                  loadOptions,
                );
                return <AuthSentPage {...context} />;
              },
            },
            {
              path: paths.bookingMailFail.subPath,
              isNotAllowedFirstLanding: true,
              async action(context: any) {
                const AuthMailFailPage = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-AuthMailFailPage" */ 'pages/AuthMailFailPage'
                    ),
                  loadOptions,
                );
                return <AuthMailFailPage {...context} />;
              },
            },
            {
              path: paths.guideUser.subPath,
              async action(context: any) {
                const GuideUserPage = loadable(
                  () => import(/* webpackChunkName: "pages-GuideUserPage" */ 'pages/GuideUserPage'),
                  loadOptions,
                );
                const query = toAppSearchObject(context.query, limitations);

                const search = {
                  params: context.params,
                  query,
                };

                if (isServer) {
                  await guideUserPageResolver(props, search);
                } else if (!context.skipResolver) {
                  guideUserPageResolver(props, search);
                }

                return <GuideUserPage {...context} query={query} />;
              },
            },
            {
              path: '/',
              async action(context: any) {
                const BookingStepWrapper = loadable(
                  () => import(/* webpackChunkName: "pages-BookingStep" */ 'pages/BookingStep'),
                  loadOptions,
                );
                const children = await context.next();
                return (
                  <BookingStepWrapper {...context} key="bookingSteps">
                    {children}
                  </BookingStepWrapper>
                );
              },
              children: [
                {
                  path: paths.bookingStep1.subPath,
                  isReservationManagementPage: true,
                  pageName: RAT_PAGE_TYPE_OBJ.bookingStep1.parentName,
                  async action(context: any) {
                    const BookingStep1 = loadable(
                      () =>
                        import(/* webpackChunkName: "pages-BookingStep1" */ 'pages/BookingStep1'),
                      loadOptions,
                    );
                    const query = toAppSearchObject(context.query, limitations) as BS1Query;
                    const search = { params: context.params, query };

                    if (isServer) {
                      await bookingStep1PageResolver(props, search);
                    } else if (!context.skipResolver) {
                      await bookingStep1PageResolver(props, search);
                    }
                    return <BookingStep1 {...context} />;
                  },
                },
                {
                  path: paths.bookingStep2.subPath,
                  isReservationManagementPage: true,
                  pageName: RAT_PAGE_TYPE_OBJ.bookingStep2.parentName,
                  async action(context: any) {
                    const BookingStep2 = loadable(
                      () =>
                        import(/* webpackChunkName: "pages-BookingStep2" */ 'pages/BookingStep2'),
                      loadOptions,
                    );

                    if (isServer) {
                      await bookingStep2PageResolver(props, context.query.stepId);
                    } else if (!context.skipResolver) {
                      await bookingStep2PageResolver(props, context.query.stepId);
                    }

                    return <BookingStep2 {...context} />;
                  },
                },
                {
                  path: paths.bookingStep3.subPath,
                  isReservationManagementPage: true,
                  isNotAllowedFirstLanding: true,
                  pageName: RAT_PAGE_TYPE_OBJ.bookingStep3.parentName,
                  async action(context: any) {
                    const BookingStep3 = loadable(
                      () =>
                        import(/* webpackChunkName: "pages-BookingStep3" */ 'pages/BookingStep3'),
                      loadOptions,
                    );
                    return <BookingStep3 {...context} />;
                  },
                },
              ],
            },
          ],
        },
        {
          path: baseUrl.cancellation,
          children: [
            {
              path: paths.cancellationStep1.subPath,
              isReservationManagementPage: true,
              pageName: RAT_PAGE_TYPE_OBJ.cancelStep1.parentName,
              async action(context: any) {
                const search = { params: context.params, query: context.query };
                const CancelStep1 = loadable(
                  () => import(/* webpackChunkName: "pages-CancelStep1" */ 'pages/CancelStep1'),
                  loadOptions,
                );

                if (isServer) {
                  await cancelStep1Resolver(props, search);
                } else if (!context.skipResolver) {
                  cancelStep1Resolver(props, search);
                }
                return <CancelStep1 {...context} />;
              },
            },
            {
              path: paths.cancellationStep2.subPath,
              isNotAllowedFirstLanding: true,
              isReservationManagementPage: true,
              async action(context: any) {
                const CancelStep2 = loadable(
                  () => import(/* webpackChunkName: "pages-CancelStep2" */ 'pages/CancelStep2'),
                  loadOptions,
                );
                return <CancelStep2 {...context} />;
              },
            },
            {
              path: paths.legacyCancellationStep2.subPath,
              isNotAllowedFirstLanding: true,
              async action(context: any) {
                const LegacyCancelStep2 = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LegacyCancelStep2" */ 'pages/LegacyCancelStep2'
                    ),
                  loadOptions,
                );
                return <LegacyCancelStep2 {...context} />;
              },
            },
          ],
        },
        {
          path: baseUrl.modification,
          children: [
            {
              path: paths.modificationStep1.subPath,
              isReservationManagementPage: true,
              pageName: RAT_PAGE_TYPE_OBJ.modification.parentName,
              async action(context: any) {
                const ModificationStep1 = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-ModificationStep1" */ 'pages/ModificationStep1'
                    ),
                  loadOptions,
                );
                const search = { params: context.params };

                if (isServer) {
                  await modificationStep1Resolver(props, search);
                } else if (!context.skipResolver) {
                  modificationStep1Resolver(props, search);
                }

                return <ModificationStep1 {...context} />;
              },
            },
            {
              path: paths.modificationStep2.subPath,
              isReservationManagementPage: true,
              pageName: RAT_PAGE_TYPE_OBJ.modificationStep2.parentName,
              async action(context: any) {
                const ModificationStep2 = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-ModificationStep2" */ 'pages/ModificationStep2'
                    ),
                  loadOptions,
                );
                const search = { params: context.params, query: context.query };

                if (isServer) {
                  await modificationStep2Resolver(props, search);
                } else if (!context.skipResolver) {
                  modificationStep2Resolver(props, search);
                }

                return <ModificationStep2 {...context} />;
              },
            },
            {
              path: paths.modificationStep3.subPath,
              isNotAllowedFirstLanding: true,
              isReservationManagementPage: true,
              pageName: RAT_PAGE_TYPE_OBJ.modificationStep3.parentName,
              async action(context: any) {
                const ModificationStep3 = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-ModificationStep3" */ 'pages/ModificationStep3'
                    ),
                  { ...loadOptions, ssr: false },
                );
                const search = { params: context.params };

                if (isServer) {
                  await modificationStep3Resolver(props, search);
                } else if (!context.skipResolver) {
                  await modificationStep3Resolver(props, search);
                }

                return <ModificationStep3 {...context} />;
              },
            },
          ],
        },
        {
          path: baseUrl.reservation,
          children: [
            {
              path: paths.reservationList.subPath,
              isReservationManagementPage: true,
              async action(context: any) {
                const ReservationListPage = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-ReservationListPage" */ 'pages/ReservationListPage'
                    ),
                  loadOptions,
                );
                const store = props.store.getState();
                const hasToken = store.authCode.item.token;
                // if there's token assume it's from reservation entry.
                if (!store.member.isMember && !hasToken) {
                  props.store.dispatch(pushLocation(paths.top.pathResolver()));
                  return;
                }

                if (isServer) {
                  await reservationListPageResolver(props, context.query);
                } else if (!context.skipResolver) {
                  reservationListPageResolver(props, context.query);
                }
                return <ReservationListPage {...context} />;
              },
            },
            {
              path: paths.legacyReservationList.subPath,
              isReservationManagementPage: true,
              async action(context: any) {
                const LegacyReservationListPage = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LegacyReservationListPage" */ 'pages/LegacyReservationListPage'
                    ),
                  loadOptions,
                );
                const store = props.store.getState();
                const hasToken = store.authCode.item.token;
                // if there's token assume it's from reservation entry.
                if (!store.member.isMember && !hasToken) {
                  props.store.dispatch(pushLocation(paths.top.pathResolver()));
                  return;
                }

                if (isServer) {
                  await legacyReservationListPageResolver(props, context.query);
                } else if (!context.skipResolver) {
                  legacyReservationListPageResolver(props, context.query);
                }
                return <LegacyReservationListPage {...context} />;
              },
            },
            {
              path: paths.reservationDetail.subPath,
              isReservationManagementPage: true,
              pageName: 'reservation_details',
              async action(context: any) {
                const ReservationDetails = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-ReservationDetails" */ 'pages/ReservationDetails'
                    ),
                  loadOptions,
                );
                const search = { params: context.params };

                if (isServer) {
                  await reservationDetailsPageResolver(props, search);
                } else if (!context.skipResolver) {
                  await reservationDetailsPageResolver(props, search);
                }
                return <ReservationDetails {...context} />;
              },
            },
            {
              path: paths.legacyReservationDetail.subPath,
              pageName: 'reservation_details',
              async action(context: any) {
                const LegacyReservationDetails = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LegacyReservationDetails" */ 'pages/LegacyReservationDetails'
                    ),
                  loadOptions,
                );

                const query = toAppSearchObject(context.query, limitations) as { email: string };
                const search = { params: context.params, query };

                if (isServer) {
                  await legacyReservationDetailsPageResolver(props, search);
                } else if (!context.skipResolver) {
                  legacyReservationDetailsPageResolver(props, search);
                }
                return <LegacyReservationDetails {...search} />;
              },
            },
            {
              path: paths.reservationLogin.subPath,
              async action(context: any) {
                const store = props.store.getState();
                const hasToken = store.authCode.item.token;
                if (store.member.isMember || hasToken) {
                  props.store.dispatch(pushLocation(paths.reservationList.path));
                  return;
                }

                const GuestEntrance = loadable(
                  () => import(/* webpackChunkName: "pages-GuestEntrance" */ 'pages/GuestEntrance'),
                  loadOptions,
                );
                return <GuestEntrance />;
              },
            },
          ],
        },
        {
          path: paths.couponList.path,
          pageName: RAT_PAGE_TYPE_OBJ.couponSearch.parentName,
          async action(context: any) {
            const CouponListPage = loadable(
              () => import(/* webpackChunkName: "pages-CouponListPage" */ 'pages/CouponListPage'),
              loadOptions,
            );
            const search = { query: { offset: context.offset, limit: context.limit } };

            if (isServer) {
              await couponListPageResolver(props, search);
            } else if (!context.skipResolver) {
              couponListPageResolver(props, search);
            }
            return <CouponListPage {...context} />;
          },
        },
        {
          path: paths.couponDetail.path,
          pageName: RAT_PAGE_TYPE_OBJ.couponDetail.parentName,
          async action(context: any) {
            const CouponDetailPage = loadable(
              () =>
                import(/* webpackChunkName: "pages-CouponDetailPage" */ 'pages/CouponDetailPage'),
              loadOptions,
            );
            const search = { params: context.params };

            if (isServer) {
              await couponDetailPageResolver(props, search);
            } else if (!context.skipResolver) {
              couponDetailPageResolver(props, search);
            }
            return <CouponDetailPage {...context} />;
          },
        },
        {
          path: paths.bookmarkList.path,
          pageName: RAT_PAGE_TYPE_OBJ.bookmarkList.parentName,
          async action(context: any) {
            const BookmarkListPage = loadable(
              () =>
                import(/* webpackChunkName: "pages-BookmarkListPage" */ 'pages/BookmarkListPage'),
              loadOptions,
            );
            const search = { query: { offset: context.offset, limit: context.limit } };

            if (isServer) {
              await bookmarkListPageResolver(props, search);
            } else if (!context.skipResolver) {
              bookmarkListPageResolver(props, search);
            }
            return <BookmarkListPage {...context} />;
          },
        },
        {
          path: paths.notificationList.path,
          pageName: RAT_PAGE_TYPE_OBJ.notifications.parentName,
          async action(context: any) {
            const NotificationListPage = loadable(
              () =>
                import(/* webpackChunkName: "pages-NotificationsPage" */ 'pages/NotificationsPage'),
              loadOptions,
            );
            if (isServer) {
              await notificationsPageResolver(props);
            } else if (!context.skipResolver) {
              notificationsPageResolver(props);
            }
            return <NotificationListPage {...context} />;
          },
        },
        {
          path: paths.notificationDetail.path,
          pageName: RAT_PAGE_TYPE_OBJ.notificationDetails.parentName,
          async action(context: any) {
            const NotificationDetails = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-NotificationDetails" */ 'pages/NotificationDetails'
                ),
              loadOptions,
            );
            const search = { params: context.params };
            if (isServer) {
              await notificationDetailsResolver(props, search);
            } else if (!context.skipResolver) {
              notificationDetailsResolver(props, search);
            }
            return <NotificationDetails {...context} />;
          },
        },
        {
          path: paths.logout.path,
          isNotAllowedFirstLanding: true,
          async action(context: any) {
            const LogoutPage = loadable(
              () => import(/* webpackChunkName: "pages-LogoutPage" */ 'pages/LogoutPage'),
              loadOptions,
            );

            return <LogoutPage {...context} />;
          },
        },
        {
          path: paths.loginError.path,
          isNotAllowedFirstLanding: true,
          async action(context: any) {
            const ErrorPage = loadable(
              () => import(/* webpackChunkName: "pages-ErrorPage" */ 'pages/ErrorPage'),
              loadOptions,
            );

            return (
              <ErrorPage
                icon={<Wifi />}
                mainText="Sorry but there is a problem happens to our site, please visit us later."
                {...context}
              />
            );
          },
        },
        {
          path: paths.loginOrGuest.path,
          isGuestOnly: true,
          pageName: RAT_PAGE_TYPE_OBJ.loginOrGuest.parentName,
          async action(context: any) {
            const LoginOrGuestPage = loadable(
              () =>
                import(/* webpackChunkName: "pages-LoginOrGuestPage" */ 'pages/LoginOrGuestPage'),
              loadOptions,
            );

            const query = toAppSearchObject(context.query, limitations);

            if (isServer) {
              await loginOrGuestPageResolver(props, query);
            } else if (!context.skipResolver) {
              loginOrGuestPageResolver(props, query);
            }

            return <LoginOrGuestPage {...context} />;
          },
        },
        {
          path: paths.accountSetting.path,
          isMemberOnly: true,
          async action(context: any) {
            const AccountSettingsPage = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-AccountSettingsPage" */ 'pages/AccountSettingsPage'
                ),
              loadOptions,
            );

            if (isServer) {
              await accountSettingsPageResolver(props);
            } else if (!context.skipResolver) {
              accountSettingsPageResolver(props);
            }

            return <AccountSettingsPage {...context} />;
          },
        },
        {
          path: paths.emailSubscriptionManagement.path,
          pageName: RAT_PAGE_TYPE_OBJ.mailSubscription.parentName,
          async action(context: any) {
            const EmailSubscriptionManagementPage = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-EmailSubscriptionManagementPage" */ 'pages/EmailSubscriptionManagementPage'
                ),
              loadOptions,
            );

            if (isServer) {
              await emailSubscriptionManagementPageResolver(props);
            } else if (!context.skipResolver) {
              emailSubscriptionManagementPageResolver(props);
            }

            return <EmailSubscriptionManagementPage {...context} />;
          },
        },
        {
          path: paths.signIn.path,
          async action(context: any) {
            const LoginPage = loadable(
              () => import(/* webpackChunkName: "pages-LoginPage" */ 'pages/LoginPage'),
              loadOptions,
            );
            return <LoginPage {...context} />;
          },
        },
        {
          path: paths.privacyPolicy.path,
          async action(context: any) {
            const PrivacyPolicyPage = loadable(
              () =>
                import(/* webpackChunkName: "pages-PrivacyPolicyPage" */ 'pages/PrivacyPolicyPage'),
              loadOptions,
            );

            if (isServer) {
              await privacyPolicyPageResolver(props, context);
            } else if (!context.skipResolver) {
              await privacyPolicyPageResolver(props, context);
            }

            return <PrivacyPolicyPage />;
          },
        },
        {
          path: paths.offline.path,
          isNotAllowedFirstLanding: true,
          action() {
            const OfflinePage = loadable(() =>
              import(/* webpackChunkName: "pages-OfflinePage" */ 'pages/OfflinePage'),
            );
            return <OfflinePage />;
          },
        },
        ...(isWithInCampaignPeriod
          ? [
              {
                path: paths.eSimCampaign.path,
                async action(context: any) {
                  const ESimCampaign = loadable(
                    () => import(/* webpackChunkName: "pages-ESimCampaign" */ 'pages/ESimCampaign'),
                    loadOptions,
                  );

                  const query = toAppSearchObject(context.query, limitations);

                  return <ESimCampaign {...context} query={query} />;
                },
              },
            ]
          : []),
        {
          // also resolve paths.notFound.path
          path: '(.*)',
          async action() {
            const NotFoundPage = loadable(
              () => import(/* webpackChunkName: "pages-NotFoundPage" */ 'pages/NotFoundPage'),
              loadOptions,
            );
            return <NotFoundPage />;
          },
        },
      ],
    },
  ];
  return new UniversalRouter(routes, {
    resolveRoute(context, params) {
      const currentRoute = context.route as {
        path: string;
        pageName: string;
        isProviderListPage: boolean;
        isReservationManagementPage?: boolean;
        isMemberOnly: boolean;
        isGuestOnly: boolean;
        isNotAllowedFirstLanding?: boolean;
      } & typeof context.route;
      const isClient = typeof window !== 'undefined';

      if (isNotEmptyValue(currentRoute.pageName)) {
        props.store?.dispatch(setPreviousPageName(currentRoute.pageName));
      }

      if (isServer && currentRoute.isNotAllowedFirstLanding) {
        props.store?.dispatch(pushLocation(paths.top.pathResolver()));
        return undefined;
      }
      const store = props.store.getState();
      const tokenPrefix = `${TOKEN_KEY}=@`;
      const isNotLogin =
        (isServer && !props.req?.headers?.cookie?.includes(tokenPrefix)) ||
        (isClient && !store.authCode?.item?.token); // SSR || CSR

      const { router } = props.store?.getState();
      const {
        location: { location },
        baseUrl,
      } = router;

      // We need to close the map dialog when we are not on providerListPage it stays open on rout changes and back button click etc
      if (currentRoute.path?.length > 1 && !currentRoute.isProviderListPage) {
        props.store?.dispatch(updateMapExpansionState(false));
      }

      if (currentRoute.isMemberOnly && isNotLogin) {
        const callbackUrl = baseUrl + location.pathname + location.search;
        props.store?.dispatch(pushLocation(`${paths.signIn.path}?target_url=${callbackUrl}`));
        return undefined;
      }

      if (currentRoute.isGuestOnly && !isNotLogin) {
        // directly navigate user to callbackUrl(BS1) when user is member
        const queryString = parseQueryString(context.search);
        const url = queryString?.callback?.split(baseUrl)?.[1];
        if (url) {
          props.store?.dispatch(pushLocation(url));
        } else {
          props.store?.dispatch(pushLocation(paths.top.pathResolver()));
        }
        return undefined;
      }

      if (currentRoute.isReservationManagementPage) {
        if (isNotLogin) {
          const GuestEntrance = loadable(
            () => import(/* webpackChunkName: "pages-GuestEntrance" */ 'pages/GuestEntrance'),
            loadOptions,
          );
          return <GuestEntrance />;
        }
      } else if (isClient && isEmptyArray(currentRoute.children)) {
        const {
          authCode: { isGuestBookingUser },
        } = props.store?.getState();
        const isGuestBooking = getCookie(IS_GUEST_BOOKING_USER);

        if (isGuestBookingUser || isGuestBooking) {
          // reset guest booking state when the user is navigated to other pages instead of reservation management pages
          props.store?.dispatch(setIsGuestBookingUser(false));
          props.store?.dispatch(guestLogout());
        }
      }

      if (typeof currentRoute.action === 'function') {
        if (context.search) {
          context.query = parseQueryString(context.search);
        }

        return context.route.action && context.route.action(context, params);
      }
      return undefined;
    },
  });
};
