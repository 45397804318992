import dayjs from 'dayjs';

import { DEFAULT_ADULT } from '@travel/traveler-core/constants';
import { SEOExtraParams } from '@travel/traveler-core/utils/seo';

import { fetchAreaRelated } from 'store/areaDetails/actions';
import { fetchBanner } from 'store/banner/actions';
import { fetchProviderList, fetchSEOdata } from 'store/providerList/actions';
import { fetchCouponDetails } from 'store/salesPromotion/actions';
import { getCouponDetails } from 'store/salesPromotion/selectors';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constants/defaultValue';
import { SORT_OPTIONS } from 'constants/provider';
import { universalRouterProps } from 'core/universalRouter/types';
import { RoomFormQuery, SearchFormQuery } from 'ProviderList-Types';
import { CouponDetails } from 'SalesPromotion-Types';

import { ListTypes } from '../ProviderListPage/components/ProviderList';
import { DEFAULT_FEATURE_COUNTS, DEFAULT_MEDIA_COUNTS } from './constants';

export type RoomFormRequestBody = {
  startDate?: string | null;
  endDate?: string | null;
  checkInDate?: string | null;
  checkOutDate?: string | null;
  noOfUnits?: string | number;
  adults?: string | number;
  childrenAges?: number[];
};

export type DefaultQueryParams = {
  checkInDate?: string | null;
  checkOutDate?: string | null;
  noOfUnits?: string | number;
  adults?: string | number;
  childrenAges?: number[];
  pathId?: string;
};

export type SearchRequestBody = {
  limit: string | number;
  offset: string | number;
  pathId?: string;
  minPrice?: string;
  maxPrice?: string;
  starRatings?: number[];
  reviewRatings?: number[];
  freeCancellation?: boolean;
  features?: string[];
  featureManagementNames?: string[];
  sort?: string;
  order?: string;
  featureCounts?: number;
  mediaCounts?: number;
  // coupon search
  coupon?: {
    id: string;
    applicableArea?: {
      name: string;
      placeId: string;
    };
    applicabilities?: {
      providerId: string;
      ratePlanIds?: string[];
    }[];
  };
} & RoomFormRequestBody;

export type SearchProps = {
  params: { pageIdentifier: string };
  query?: QueryParams;
};

//location only for map API
type Location = {
  lat?: number;
  lng?: number;
  zoomLevel?: number;
};

type QueryParams = SearchFormQuery & ListTypes & SEOExtraParams & Location;

const DEFAULT_LIMIT = 20;
const DEFAULT_OFFSET = 0;
const DATE_FORMAT = 'YYYY-MM-DD';

export const FIRST_PAGE_PARAMS = {
  page: undefined,
  offset: undefined,
};

export const getValidNumberArray = (stringOfNumber?: string | Array<string>) => {
  const list = Array.isArray(stringOfNumber) ? stringOfNumber : (stringOfNumber || '').split(',');
  return list.reduce((result: Array<number>, item) => {
    if (!item) {
      return result;
    }
    return [...result, Number(item)];
  }, []);
};

export function getDefaultRoomFormQueryParams(query: RoomFormQuery) {
  const checkInDate = query?.startDate && dayjs(query.startDate).format(DATE_FORMAT);
  const checkOutDate = query?.endDate && dayjs(query.endDate).format(DATE_FORMAT);

  return {
    checkInDate: checkInDate || null,
    checkOutDate: checkOutDate || null,
    adults: Number(query.adults) || DEFAULT_ADULT,
    childrenAges: getValidNumberArray(query.childrenAges),
    rooms: Number(query.noOfUnits) || 1,
  };
}

export function getDefaultQueryParams(query: SearchRequestBody) {
  return {
    checkInDate: query.checkInDate,
    checkOutDate: query.checkOutDate,
    adults: Number(query.adults) || DEFAULT_ADULT,
    childrenAges: query.childrenAges,
    rooms: Number(query.noOfUnits) || 1,
    pathId: query.pathId,
  } as DefaultQueryParams;
}

function areArraysEqual(arr1: any, arr2: any) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const element1 = arr1[i];
    const element2 = arr2[i];

    if (Array.isArray(element1) && Array.isArray(element2)) {
      if (!areArraysEqual(element1, element2)) {
        return false;
      }
    } else if (element1 !== element2) {
      return false;
    }
  }
  return true;
}

export function shouldUpdateProviderList(
  prevQuery: Record<string | number | symbol, any>,
  currQuery: Record<string | number | symbol, any>,
) {
  const keys = Object.keys(prevQuery);

  for (let key of keys) {
    const val1 = prevQuery[key];
    const val2 = currQuery[key];

    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!areArraysEqual(val1, val2)) {
        return true;
      }
    } else if (val1 !== val2) {
      return true;
    }
  }
  return false;
}

export function getRequestBody(search: SearchProps, coupon?: CouponDetails) {
  const query = search?.query || (EMPTY_OBJECT as QueryParams);
  const params = search?.params;

  // sort
  const sortOption = SORT_OPTIONS[`${query.sortKey}`] || SORT_OPTIONS['recommended'];
  // pagination
  const limit = query.limit || DEFAULT_LIMIT;
  // -- if there is offset, then use offset
  // -- if no offset, re-calculate from page number
  const offset =
    query.offset !== undefined
      ? Number(query.offset)
      : query.page
      ? (Number(query.page) - 1) * limit
      : DEFAULT_OFFSET;

  // page identifier
  const identifier = decodeURIComponent(params?.pageIdentifier) || '';
  // to support the case when navigate from coupon item which designatedFlow is "HOTEL_LIST"
  const isProviderId = identifier.match(/^\d+$/);
  const isCouponFlow = Boolean(query.salesPromotionId && coupon);
  const mapSearchParams =
    query.lat && query.lng
      ? {
          //google map search
          latitude: query.lat || '',
          longitude: query.lng || '',
          zoomLevel: query.zoomLevel || '',
        }
      : EMPTY_OBJECT;

  const couponParams = isCouponFlow
    ? {
        coupon: {
          id: query.salesPromotionId || '',
          applicableArea: coupon?.conditions.applicableArea,
        },
      }
    : isProviderId
    ? // To fallback in case that there is no path id but have only place id
      { coupon: { applicableArea: { placeId: identifier, name: '' } } }
    : EMPTY_OBJECT;

  // features
  const queryFeatures: Array<string> = Array.isArray(query.features)
    ? query.features
    : query.features?.split(',') || EMPTY_ARRAY;

  const { features, featureManagementNames } = queryFeatures.reduce(
    (result: { features: Array<string>; featureManagementNames: Array<string> }, feature) => {
      if (!feature) {
        return result;
      }
      if (feature.match(/^\d+$/)) {
        return { ...result, features: [...result.features, feature] };
      }
      return { ...result, featureManagementNames: [...result.featureManagementNames, feature] };
    },
    { features: EMPTY_ARRAY, featureManagementNames: EMPTY_ARRAY },
  );

  const filterComponent = Array.isArray(query.filterComponent)
    ? query.filterComponent
    : (query.filterComponent && query.filterComponent?.split(',')) || null;

  return {
    ...getDefaultRoomFormQueryParams(query),
    pathId: !isProviderId ? identifier : undefined,
    features,
    featureManagementNames,
    ...(filterComponent && {
      filter: {
        managementNames: filterComponent,
      },
    }),

    minPrice: query.minPrice || '',
    maxPrice: query.maxPrice || '',
    starRatings: getValidNumberArray(query['starRatings[]']),
    reviewScores: getValidNumberArray(query['reviewScores[]']),
    freeCancellation: query.freeCancellation === 'true',

    limit,
    offset,
    mediaCounts: DEFAULT_MEDIA_COUNTS,
    featureCounts: DEFAULT_FEATURE_COUNTS,

    ...sortOption.queries,
    ...mapSearchParams,
    ...couponParams,
  };
}

export default async ({ store }: universalRouterProps, search: SearchProps) => {
  // currently we use top banner on provider list as well
  const pageName = 'top';
  const { dispatch } = store;
  const couponId = search.query?.salesPromotionId;

  let couponDetail;
  const page = search.query?.page
    ? !isNaN(Number(search.query?.page))
      ? Number(search.query?.page)
      : 1
    : 1;

  if (couponId) {
    await dispatch(fetchCouponDetails(couponId));
    couponDetail = getCouponDetails(couponId)(store.getState());
  }

  const requestBody = getRequestBody({ ...search, query: { ...search.query, page } }, couponDetail);

  return Promise.all([
    dispatch(fetchBanner(pageName)),
    dispatch(fetchProviderList(requestBody, false, { page })),
    dispatch(fetchAreaRelated({ pathId: search.params?.pageIdentifier }, true)),
  ]).then(() => dispatch(fetchSEOdata(search.params?.pageIdentifier, search.query?.sortKey, true)));
};
